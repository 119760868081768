import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "./App.css";
import Login from "./components/Login";
import FunctionAppsList from "./components/FunctionAppsList";
import Migration from "./components/Migration";
import MigrationList from "./components/MigrationList";
import MigrationDetails from "./components/MigrationDetails";
import Configure from "./components/accelerus/Configure";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { ChartPieIcon, CursorArrowRaysIcon } from "@heroicons/react/24/outline";
import {
  LoadingProvider,
  useLoading,
} from "./components/shared/LoadingContext";
import Layout from "./components/shared/Layout";
import Loading from "./components/shared/Loading";

const migrations = [
  {
    name: "XUNO",
    description: "Get a better understanding of your traffic",
    icon: ChartPieIcon,
  },
  {
    name: "GradeXpert",
    description: "Speak directly to your customers",
    icon: CursorArrowRaysIcon,
  },
];

const AppContent = () => {
  const isAuthenticated = useIsAuthenticated();
  const { loading } = useLoading();

  return (
    <div className="App min-h-screen bg-gray-100">
      <Layout>
        <main className="container mx-auto p-4 flex-1 flex flex-col h-full">
          {loading && <Loading />}
          {isAuthenticated ? (
            <Routes>
              <Route path="/" element={<Navigate to="GradeXpert/migration/list" replace />} />
              <Route path="/resources" element={<FunctionAppsList />} />
              <Route path="/:application/migration/" element={<Migration />} />
              <Route path="/:application/migration/list/" element={<MigrationList />} />
              <Route path="/:application/migration/detail" element={<MigrationDetails />} />
              <Route path="/accelerus/configure/db" element={<Configure />} />
            </Routes>
          ) : (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <Routes>
                <Route path="/" element={<Login />} />
              </Routes>
            </MsalAuthenticationTemplate>
          )}
        </main>
      </Layout>
    </div>
  );
};

function App() {
  return (
    <LoadingProvider>
      <Router>
        <AppContent />
      </Router>
    </LoadingProvider>
  );
}

export default App;
